import { Icons } from "@sphtech/dmg-design-system";

import { ConfigHeader } from "../../types/config";

export const configHeader: ConfigHeader = {
  megaMenu: {
    newsletter: {
      text: "Your front row access to the latest in fashion, beauty and celebrities. Delivered weekly to your inbox.",
      buttonText: "SIGN UP",
      url: "/newsletter-signup",
    },
    closeButton: {
      type: "text",
      placement: "right",
      TextOrIcon: "Close",
    },
  },
  socialMedia: {
    target: "_blank",
    icons: [
      {
        icon: Icons.facebookIcon,
        name: "Facebook",
        href: "https://www.facebook.com/harpersbazaarsingapore/",
      },
      {
        icon: Icons.TwitterIcon,
        name: "Twitter",
        href: "https://x.com/HarpersBazaarSG",
      },
      {
        icon: Icons.YoutubeIcon,
        name: "Youtube",
        href: "https://www.youtube.com/harpersbazaarsingaporemagazine",
      },
      {
        icon: Icons.InstagramIcon,
        name: "Instagram",
        href: "https://www.instagram.com/harpersbazaarsg/",
      },
      {
        icon: Icons.TiktokIcon,
        name: "Tiktok",
        href: "https://www.tiktok.com/@harpersbazaarsg",
      },
    ],
  },
};
